<template>
  <div>
    <v-container fluid>
      <div class="main">
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-space-between mb-3">
              <v-spacer/>

              <v-btn class="rounded-lg"
                     color="gifty"
                     dark
                     @click="$refs.createFlexyOfferDialog.open()"
                     depressed
              >
                <v-icon left>mdi-plus</v-icon>
                Ajouter un offre
              </v-btn>
            </div>

            <v-card class="rounded-lg shadow">
              <v-overlay :value="overlay" absolute color="white" opacity="0.5">
                <v-progress-circular
                    color="primary"
                    indeterminate
                    size="50"
                ></v-progress-circular>
              </v-overlay>

              <v-card-title>
                <div>
                  <v-text-field
                      v-model="keyword"
                      append-icon="mdi-magnify"
                      clearable
                      dense
                      filled
                      hide-details
                      placeholder="Rechercher..."
                      rounded
                      single-line

                  />
                </div>
                <v-spacer/>

                <v-btn color="grey"
                       icon
                       @click="getData">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>

              </v-card-title>

              <v-divider/>

              <v-card-text v-if="isLoading">
                <v-skeleton-loader type="table"/>
              </v-card-text>

              <div v-else>
                <v-card-text class="pa-0">

                  <v-data-table v-if="offers.length"
                                :headers="headers"
                                :items="offers"
                                :search="keyword"
                  >

                    <template v-slot:item.status="{ item }">
                      <v-switch v-model="item.is_active"
                                class="pa-0 ma-0"
                                color="success"
                                hide-details
                                readonly
                                @click="changeStatus(item.id)"></v-switch>

                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-btn icon @click="$refs.createFlexyOfferDialog.open(item)">
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                      <v-btn icon @click="remove(item.id)">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </template>

                    <template v-slot:item.amount="{ item }">
                      <span class="font-weight-medium gifty--text">
                      {{ CurrencyFormatting(item.amount) }} DZD
                      </span>
                    </template>

                    <template v-slot:item.image="{ item }">
                      <v-img width="40"
                             class="py-2"
                             contain
                             v-if="item.image"
                             :src="fileUrl + item.image"
                      />
                      <span v-else>-</span>
                    </template>

                  </v-data-table>

                  <div v-else>
                    <NoResults @reload="getData"/>
                  </div>

                </v-card-text>

              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <CreateFlexyOfferDialog ref="createFlexyOfferDialog" @refresh="getData"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import CreateFlexyOfferDialog from "./components/CreateFlexyOfferDialog.vue";

export default {
  components: {CreateFlexyOfferDialog, NoResults},
  data() {
    return {
      id: '',
      fileUrl: process.env.VUE_APP_FILE_URL,
      keyword: '',
      overlay: false,
      isLoading: false,
      offers: [],
      headers: [
        {
          text: 'Status',
          align: 'start',
          sortable: false,
          value: 'status',
        },
        {
          text: 'Nom',
          value: 'name',
        },
        {
          text: 'Type',
          value: 'flexy_offer_type.name',
        },
        {
          text: 'Service',
          value: 'service.name',
        },
        {
          text: 'Image',
          value: 'image',
        },
        {
          text: 'Montant',
          value: 'amount',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    getData() {
      this.isLoading = true
      HTTP.get('/v1/flexy-offers').then(res => {
        this.offers = res.data.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    remove(id) {
      this.id = id;
      this.$confirm_dialog = true;
    },
    deleteItem() {
      this.overlay = true;
      HTTP.post("/v1/flexy-offers/delete", {
        id: this.id,
      }).then(() => {
        this.overlay = false;
        let i = this.offers.findIndex((el) => el.id === this.id);
        this.$delete(this.offers, i);
        this.id = null;
        this.$successMessage = "Cet élément a été supprimé avec succès.";
      }).catch((err) => {
        this.overlay = false;
        console.log(err);
      });
    },
    changeStatus(id) {
      this.overlay = true;
      HTTP.post("/v1/flexy-offers/change-status", {
        id: id,
      }).then(() => {
        this.overlay = false;
        let index = this.offers.findIndex(el => el.id === id)
        this.offers[index].is_active = !this.offers[index].is_active
        this.$successMessage = "Cet élément a été modifié avec succès.";
      }).catch((err) => {
        this.overlay = false;
        console.log(err);
      });
    },
  },
  created() {
    this.getData();
  },
  watch: {
    $is_confirm: function (el) {
      if (el) {
        this.deleteItem();
        this.$is_confirm = false;
      }
    }
  }
};
</script>

<style scoped></style>
